<template>
  <div>
    <div class="d-flex justify-start align-center mb-6">
      <div class="demo-space-x">
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <span class="d-block text-caption black--text">Sekolah</span>
        <span
          class="d-block text-subtitle-1 font-weight-bold black--text"
          style="line-height: 1.5rem !important"
        >
          {{ schoolName }}
        </span>
      </div>
    </div>
    <v-row>
      <v-col md="9">
        <v-card :loading="isLoadingCard">
          <v-card-title class="font-weight-bold">
            Pengumuman
          </v-card-title>
          <v-card-text class="pa-0">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                    >
                      Tanggal Mulai
                    </th>
                    <th class="text-left">
                      Tanggal Selesai
                    </th>
                    <th class="text-left">
                      Judul
                    </th>
                    <th class="text-left">
                      Pesan
                    </th>
                    <th class="text-left">
                      Set Waktu
                    </th>
                    <th class="text-left">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody v-if="announcements.length">
                  <tr
                    v-for="item in announcements"

                    :key="item.title"
                  >
                    <td>{{ item.start_date }}</td>
                    <td>{{ item.end_date }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.content }}</td>
                    <td>Permanen</td>
                    <td>
                      <v-chip
                        class="v-chip-light-bg success--text"
                        color="success"
                      >
                        {{ item.status }}
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                    >
                      Belum ada pengumuman
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3">
        <!-- <v-card
          v-for="card in cards"
          :key="card.title"
          class="mb-4"
        >
          <v-card-text>
            <div class="d-flex align-center">
              <v-avatar
                :color="card.color"
                size="40"
                class="mr-4"
              >
                <div
                  class="d-flex align-center"
                >
                  <v-img
                    width="20"
                    :src="card.icon"
                  ></v-img>
                </div>
              </v-avatar>

              <div>
                <span>{{ card.title }}</span>
                <h3>{{ card.total }}</h3>
              </div>
            </div>
          </v-card-text>
        </v-card> -->
        <v-card
          :loading="isLoadingCard"

          class="mb-4"
        >
          <v-card-text class="pa-4">
            <div class="d-flex align-center">
              <v-avatar
                color="#EC008C"
                size="40"
                class="mr-4"
              >
                <div
                  class="d-flex align-center"
                >
                  <v-img
                    width="20"
                    :src="icons.iconBook"
                  ></v-img>
                </div>
              </v-avatar>

              <div>
                <h4>E-Book</h4>
                <span class="">{{ total_ebook }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :loading="isLoadingCard"

          class="mb-4"
        >
          <v-card-text class="pa-4">
            <div class="d-flex align-center">
              <v-avatar
                color="#7D2B8B"
                size="40"
                class="mr-4"
              >
                <div
                  class="d-flex align-center"
                >
                  <v-img
                    width="20"
                    :src="icons.iconMusic"
                  ></v-img>
                </div>
              </v-avatar>

              <div>
                <h4>Audio Book</h4>
                <span class="">{{ total_audio }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          :loading="isLoadingCard"
        >
          <v-card-text class="pa-4">
            <div class="d-flex align-center">
              <v-avatar
                color="#F7B239"
                size="40"
                class="mr-4"
              >
                <div
                  class="d-flex align-center"
                >
                  <v-img
                    width="20"
                    :src="icons.iconVideo"
                  ></v-img>
                </div>
              </v-avatar>

              <div>
                <h4>Video Book</h4>
                <span class="">{{ total_video }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="6"
        cols="6"
      >
        <v-card class="pa-5">
          <div class="d-block">
            <div class="mb-4">
              <v-avatar>
                <v-img src="../../../assets/images/avatars/1.png"></v-img>
              </v-avatar>
            </div>
            <h4>E-Book</h4>
            <h3>1703</h3>
          </div>
        </v-card>
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="6"
        cols="6"
      >
        <v-card class="pa-5">
          <div class="d-block">
            <div class="mb-4">
              <v-avatar>
                <v-img src="../../../assets/images/avatars/2.png"></v-img>
              </v-avatar>
            </div>
            <h4>Video Book</h4>
            <h3>1154</h3>
          </div>
        </v-card>
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="6"
        cols="6"
      >
        <v-card class="pa-5">
          <div class="d-block">
            <div class="mb-4">
              <v-avatar>
                <v-img src="../../../assets/images/avatars/3.png"></v-img>
              </v-avatar>
            </div>
            <h4>Audio Book</h4>
            <h3>1150</h3>
          </div>
        </v-card>
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="6"
        cols="6"
      >
        <v-card class="pa-5">
          <div class="d-block">
            <div class="mb-4">
              <v-avatar>
                <v-img src="../../../assets/images/avatars/4.png"></v-img>
              </v-avatar>
            </div>
            <h4>Siswa</h4>
            <h3>16</h3>
          </div>
        </v-card>
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="6"
        cols="6"
      >
        <v-card class="pa-5">
          <div class="d-block">
            <div class="mb-4">
              <v-avatar>
                <v-img src="../../../assets/images/avatars/5.png"></v-img>
              </v-avatar>
            </div>
            <h4>Guru</h4>
            <h3>18</h3>
          </div>
        </v-card>
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="6"
        cols="6"
      >
        <v-card class="pa-5">
          <div class="d-block">
            <div class="mb-4">
              <v-avatar>
                <v-img src="../../../assets/images/avatars/6.png"></v-img>
              </v-avatar>
            </div>
            <h4>Orang Tua</h4>
            <h3>16</h3>
          </div>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mdiTownHall } from '@mdi/js'
import iconBook from '@/assets/icons/white/book.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconVideo from '@/assets/icons/white/play.svg'

export default {
  data() {
    return {
      schoolName: '',
      icons: {
        mdiTownHall,
        iconBook,
        iconMusic,
        iconVideo,
      },
      announcements: [],
      total_ebook: 0,
      total_audio: 0,
      total_video: 0,
      cards: [
        {
          title: 'E-Book',
          total: 0,
          icon: iconBook,
          color: '#EC008C',
        },
        {
          title: 'Audio Book',
          total: 0,
          icon: iconMusic,
          color: '#7D2B8B',
        },
        {
          title: 'Video Book',
          total: 0,
          icon: iconVideo,
          color: '#F7B239',
        },
      ],
      school: [],
      school_uuid: '',
      isLoadingCard: true,
    }
  },
  async mounted() {
    await this.getSchoolUuid()
    await this.getTotal()
    await this.getAnnouncement()

    this.schoolName = JSON.parse(localStorage.getItem('user')).user.details[0].school.name
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async getTotal() {
      await this.$services.ApiServices.list('dashboardteacher').then(
        ({ data }) => {
          this.totals = data.data
          this.total_ebook = data.data.count_ebook
          this.total_audio = data.data.count_audio
          this.total_video = data.data.count_video
        },
        err => console.error(err),
      )
      this.isLoadingCard = false
    },
    async getAnnouncement(params = {}) {
      await this.$services.ApiServices.list('announcement', { ...params, school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.announcements = data.data
        },
      )
    },
  },
}
</script>

<style>
</style>
